// THIS FILE IS GENERATED AUTOMATICALLY DURING BUILD
// SHOULD NOT BE EDITED MANUALLY
import { version } from '../../../../../package.json';
export const versionInfo = {
  buildTime:'2025-02-13T15:06:57+01:00',
  gitHash:'3a9587825',
  branchName:'develop',
  tag:'v1.4.5',
  version:version
};
