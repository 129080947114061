<footer class="footer py-3" role="contentinfo">
  <div class="container"
      fxFlex
      fxLayout="column"
      fxLayout.lt-md="column"
      fxLayoutGap="10px">

    <div fxFlex fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0">
      <div fxLayout="row wrap" fxFlex="100%" fxFlex.gt-xs="60%">
        <div fxFlex="nogrow"><img class="logoMyPay" src="assets/images/logo-MyPay-ligth-blue.png" alt="Logo MyPay" height="50"></div>
        <div fxFlex="nogrow" class="mt-3 ml-2"><a href="https://www.regione.veneto.it/"><img src="assets/images/logo-regione-veneto--bw.png" alt="Logo regione Veneto"></a></div>
      </div>
      <div fxFlex="grow" ngClass="text-right my-0" ngClass.xs="text-left my-3" fxFlex="100%" fxFlex.gt-xs="40%"><span class="sr-only">Seguici su:</span>
        <a mat-icon-button style="margin-left: 12px" class="btn-facebook" href="https://www.facebook.com/RegionedelVeneto" alt="Facebook"><fa-icon aria-hidden="true" [icon]="iconFacebook" size="3x"></fa-icon><span class="sr-only">Facebook</span></a>
        <a mat-icon-button style="margin-left: 12px" class="btn-instagram" href="https://www.instagram.com/regioneveneto"><fa-icon aria-hidden="true" [icon]="iconInstagram" size="3x"></fa-icon><span class="sr-only">Instagram</span></a>
        <a mat-icon-button style="margin-left: 12px" class="btn-twitter" href="https://twitter.com/regioneveneto"><fa-icon aria-hidden="true" [icon]="iconTwitter" size="3x"></fa-icon><span class="sr-only">Twitter</span></a>
        <a mat-icon-button style="margin-left: 12px" class="btn-youtube" href="https://www.youtube.com/user/RegioneDelVeneto"><fa-icon aria-hidden="true" [icon]="iconYoutube" size="3x"></fa-icon><span class="sr-only">Youtube</span></a>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start top" fxLayoutGap="0px">
      <div fxFlex="100%" fxFlex.gt-xs="50%" class="mb-3">
        <h2 class="mat-h2">Contatti</h2>
        <address>
            Regione del Veneto <br>
            Palazzo Balbi - Dorsoduro, 3901 <br>
            30123 Venezia <br>
            P.IVA 02392630279 <br>
          <fa-icon [icon]="iconEnvelope"></fa-icon>&nbsp;
          <a href="https://www.regione.veneto.it/web/affari-generali/pec-regione-veneto">Posta Elettronica Certificata</a>
        </address>
      </div>
      <div fxFlex="100%" fxFlex.gt-xs="50%" class="mb-3">
        <div fxLayout="column" fxLayoutAlign="start start">
          <h2 class="mat-h2"><a routerLink="/info/support">Assistenza</a></h2>
          <a routerLink="/info/about">Cos’è MyPay</a>
          <a routerLink="/info/faq">FAQ</a>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start top" fxLayoutGap="0">
      <div fxFlex="100%" fxFlex.gt-sm="25%"><p>© 2021 Regione del Veneto</p></div>
      <div fxFlex="100%" fxFlex.gt-sm="75%" ngClass="text-right my-0" ngClass.xs="text-left my-3">
        <a class="ml-0" [routerLink]="" (click)="showAppInfo()">Versione</a>
        <a class="ml-2" target="_blank" href="https://www.regione.veneto.it/web/guest/accessibilita">Accessibilità</a>
        <a class="ml-2" target="_blank" href="https://form.agid.gov.it/view/be1c6da0-a0df-11ef-a1cf-79a7e161f6b8">Dichiarazione di accessibilità</a>
        <a class="ml-2" routerLink="/info/privacy">Privacy</a>
        <a class="ml-2" target="_blank" href="https://myportal-infopbl.regione.veneto.it/myportal/INFOPBL/informative/note_legali">Note Legali</a>
        <a class="ml-2" routerLink="/info/cookie">Informativa Cookie</a>
        <a class="ml-2" [routerLink]="" (click)="resetCookieConsent()">Rimuovi preferenze cookie</a>
      </div>
    </div>


  </div>
  </footer>
